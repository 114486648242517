'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import dynamic from 'next/dynamic'

import type { AbTestKeys } from '../../constants/AbTestKeys'

const ServerAbTestDynamic = dynamic(
	() =>
		import('../ServerAbTestDynamic/ServerAbTestDynamic').then(
			(mod) => mod.ServerAbTestDynamic
		),
	{
		ssr: false,
	}
)

interface ServerAbTestProps {
	featureFlagKey: AbTestKeys
}

export function ServerAbTest({
	featureFlagKey,
}: ServerAbTestProps): React.ReactNode {
	const { abTestPage, abTestLayout } = useMasterData()

	return abTestPage || abTestLayout ? (
		<ServerAbTestDynamic featureFlagKey={featureFlagKey} />
	) : null
}
