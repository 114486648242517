import { getBrandParent } from 'country-configuration/utils/brands/getBrandParent'
import { matchBrandFromUrl } from 'country-configuration/utils/brands/matchBrandFromUrl'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { usePathname } from 'next/navigation'

/**
 * Returns the brand and subBrand from the URL or the master data if the URL does not contain a brand.
 * @returns The brand and subBrand
 */
export const useUrlBrand = () => {
	const { t } = useLabels()
	const {
		brand: masterDataBrandId,
		subBrand: masterDataSubBrandId,
		country,
	} = useMasterData()
	const pathname = usePathname()
	const navBrand = matchBrandFromUrl({
		brands: country.brands,
		urlBrands: pathname.split('/'),
		translate: t,
	})

	if (!navBrand) {
		const countryParentBrand = country.brands.find(
			(countryBrand) => countryBrand.id === masterDataBrandId
		)
		const countrySubBrand = countryParentBrand?.subBrands?.find(
			(subBrand) => subBrand.id === masterDataSubBrandId
		)

		return {
			brand: countryParentBrand,
			subBrand: countrySubBrand,
		}
	}

	const navParentBrand = getBrandParent(navBrand.id, country.brands)
	const navSubBrand = navParentBrand.id !== navBrand.id ? navBrand : undefined

	return {
		brand: navParentBrand,
		subBrand: navSubBrand,
	}
}
