export enum LabelsNamespaces {
	Apps = 'Apps',
	Campaigns_Promotions = 'Campaigns_Promotions',
	Checkout = 'Checkout',
	Client = 'Client',
	Discover = 'Discover_by_Mango',
	Gift_Voucher = 'Gift_card',
	Loyalty = 'Loyalty',
	Navigation = 'Navigation',
	Plp_Pdp = 'Plp_Pdp',
	Post_Purchase = 'Post_Purchase',
	Shopping_Bag = 'Shopping_Bag',
	Shopping_Bag_Checkout = 'Shopping_Bag_Checkout',
	Transversal_Keys = 'Transversal_Keys',
	Homes_Shop = 'Homes_Shop',
	Homes_Outlet = 'Homes_Outlet',
	Menu_Shop = 'Menu_shop',
	Menu_Outlet = 'Menu_outlet',
	Menu_Common = 'Menu_Common_Keys',
	My_Addresses = 'My_addresses',
	Plp = 'PLP',
	Pdp = 'PDP',
	Filters = 'Filters',
	Payment_Checkout = 'Payment_Checkout',
	MLY_PartnersONG = 'MLY_PartnersONG',
	MLY_Club_Mango_Likes_You = 'Club_Mango_Likes_You',
}

export type Labels<T extends string = string> = Record<T, string>
