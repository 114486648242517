'use client'

import { TimeOut } from 'header/constants/timeOuts'
import { useHeaderDispatch } from 'hooks/useHeaderContext/useHeaderDispatch'
import { useUrlBrand } from 'hooks/useUrlBrand/useUrlBrand'
import { usePathname, useSearchParams } from 'next/navigation'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import type { MasterData } from 'types/masterData'

type MenuState = {
	open: boolean
	firstOpen: boolean
	brandSelected: MasterData['brand']
	subBrandSelected: MasterData['subBrand']
	selectedEntry: null | string
	openingWithKeyboardFocus: boolean
}

const INITIAL_STATE: MenuState = {
	open: false,
	firstOpen: false,
	brandSelected: null,
	subBrandSelected: null,
	selectedEntry: null,
	openingWithKeyboardFocus: false,
}

type MenuDispatch = (newState: Partial<MenuState>) => void

const INITIAL_DISPATCH: MenuDispatch = () => {
	/* */
}

type MenuProviderProps = {
	children: React.ReactNode
}

export const MenuStateContext = createContext<MenuState>(INITIAL_STATE)
export const MenuDispatchContext = createContext<MenuDispatch>(INITIAL_DISPATCH)

export const MenuStatesProvider = ({ children }: MenuProviderProps) => {
	const headerDispatch = useHeaderDispatch()
	const { brand: pageBrand, subBrand: pageSubBrand } = useUrlBrand()
	const pageBrandId = pageBrand?.id ?? null
	const pageSubBrandId = pageSubBrand?.id ?? null

	const [state, setState] = useState({
		...INITIAL_STATE,
		brandSelected: pageBrandId,
		subBrandSelected: pageSubBrandId,
	})
	const dispatch = useCallback(
		(newState: Partial<MenuState>) =>
			setState((prevState) => ({ ...prevState, ...newState })),
		[]
	)

	const pathname = usePathname()
	const searchParams = useSearchParams()
	const didRender = useRef(false)

	useEffect(() => {
		if (didRender.current) {
			dispatch({ open: false })
		} else {
			didRender.current = true
		}
	}, [pathname, searchParams])

	useEffect(() => {
		if (!state.open) {
			setTimeout(() => {
				dispatch({
					brandSelected: pageBrandId,
					subBrandSelected: pageSubBrandId,
				})
				headerDispatch({ brandId: pageBrandId, subBrandId: pageSubBrandId })
			}, TimeOut.CLOSE_BRAND_DEFAULT)
		}
	}, [state.open])

	return (
		<MenuStateContext.Provider value={state}>
			<MenuDispatchContext.Provider value={dispatch}>
				{children}
			</MenuDispatchContext.Provider>
		</MenuStateContext.Provider>
	)
}
