'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { useHeaderDispatch } from 'hooks/useHeaderContext/useHeaderDispatch'
import { IconSearchL } from 'icons/components/IconSearchL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import dynamic from 'next/dynamic'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { Keyboard } from 'types/keyboard'
import { applyStylesIf, cx } from 'utils/cx'

import { CommonGenericEvents, LocationParams } from '../../constants/analytics'
import {
	SEARCHLOCALSTORAGEKEYS,
	getSearchLocalStorage,
} from '../../utils/searchLocalStorage'

import styles from './HeaderSearchBox.module.scss'

const SearchMenu = dynamic(() =>
	import('./SearchMenu/SearchMenu').then((mod) => mod.SearchMenu)
)

type HeaderSearchBoxProps = {
	labelClassName: string
	iconClassName: string
}

export function HeaderSearchBox({
	labelClassName,
	iconClassName,
}: HeaderSearchBoxProps): React.ReactNode {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [firstSearchMenuOpen, setFirstSearchMenuOpen] = useState<boolean>(false)
	const [userHasSearched, setUserHasSearched] = useState<boolean>(false)
	const dispatch = useHeaderDispatch()
	const { t } = useLabels()
	const recentSearch = getSearchLocalStorage(
		SEARCHLOCALSTORAGEKEYS.SEARCHKEYWORDS
	)
	const pathname = usePathname()
	const searchParams = useSearchParams()

	const handleToggleShow = (isUserInteraction = true) => {
		if (!isUserInteraction) {
			setUserHasSearched(true)
			return
		}

		setIsOpen((prevIsOpen) => !prevIsOpen)

		if (isOpen) {
			sendGenericEvent(CommonGenericEvents.CLOSE)
		} else {
			sendGenericEvent(CommonGenericEvents.OPENED, {
				location: LocationParams.ICON,
				show_recentsearch: recentSearch.length > 0 ? 'yes' : 'no',
				show_recentlyview: 'no',
			})
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if ((e.key === Keyboard.Enter || e.key === Keyboard.Space) && !isOpen) {
			// We need to prevent the default behavior of the button because
			// when the button is pressed using keyboard it triggers the click event
			// which removes the class from the body to control the outline
			e.preventDefault()
			handleToggleShow()
		}

		if (e.key === Keyboard.Tab && isOpen) {
			e.preventDefault()
		}
	}

	useEffect(() => {
		if (isOpen) {
			handleToggleShow(false)
		}
	}, [pathname, searchParams])

	useEffect(() => {
		dispatch({ isSearchOpen: isOpen })

		if (isOpen) {
			setFirstSearchMenuOpen(true)
		}
	}, [isOpen])

	return (
		<>
			{firstSearchMenuOpen && (
				<>
					<div
						className={cx(
							styles.searchBackdrop,
							applyStylesIf(isOpen, styles.open),
							applyStylesIf(!isOpen, styles.close)
						)}
						data-testid='header.userMenu.search.backdrop'
						onClick={() => handleToggleShow()}
						role='button'
						aria-label={t('header.search.cancel.button')}
						tabIndex={0}
						onKeyDown={handleKeyDown}
					/>
					<SearchMenu
						handleToggleShow={handleToggleShow}
						userHasSearched={userHasSearched}
					/>
				</>
			)}
			{!isOpen && (
				<button
					type='button'
					className={styles.button}
					data-testid='header.userMenu.search.button'
					onClick={() => handleToggleShow()}
					onKeyDown={handleKeyDown}
					aria-label={t('accessibility.header.search.icon')}
					rel='nofollow'
				>
					<IconSearchL className={iconClassName} />
					<span className={labelClassName}>{t('header.search.text')}</span>
				</button>
			)}
		</>
	)
}
