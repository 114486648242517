import type { Brand, BrandId } from 'types/brands'
import { urlNormalize } from 'utils/url/urlNormalize'

import { flatBrands } from './flatBrands'
import { getBrandTranslationKeys } from './getBrandTranslationKeys'
import { getSubbrands } from './getSubBrands'

export const matchBrandFromUrl = ({
	urlBrands,
	translate,
	brands,
}: {
	urlBrands: string[]
	translate: (key: string) => string
	brands: Brand[]
}): Brand | undefined => {
	const subBrands = getSubbrands(brands)
	const decodedUrlBrands = urlBrands.map((urlBrand) =>
		urlNormalize(decodeURIComponent(urlBrand))
	)

	const urlBrandNames = flatBrands(brands).reduce<Record<BrandId, string>>(
		(encodedBrands, brand) => {
			const urlEncodedBrandName = urlNormalize(
				translate(getBrandTranslationKeys(brand.id)?.urlKey || '')
			)

			if (urlEncodedBrandName) {
				return { ...encodedBrands, [brand.id]: urlEncodedBrandName }
			}

			return encodedBrands
		},
		{} as Record<BrandId, string>
	)

	const brandFinder = (brandsList: Brand[]): Brand | undefined =>
		decodedUrlBrands.reduceRight<Brand | undefined>(
			(foundBrand, urlBrand) =>
				foundBrand ||
				brandsList.find((brand) => urlBrandNames[brand.id] === urlBrand),
			undefined
		)

	let matchedBrand = brandFinder(subBrands)

	if (!matchedBrand) {
		matchedBrand = brandFinder(brands)
	}

	return matchedBrand
}
